import React from 'react';
import { TYPING_TIMEOUT } from '../sys-constants';
import { PulseLoader } from 'react-spinners';

export default class Bubble extends React.Component {
  state = {
    changeLoader: false,
  }

  componentDidMount() {
    const { id, isFriend } = this.props;
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
    if (isFriend) {
      setTimeout(() => this.setState({ changeLoader: true }), TYPING_TIMEOUT);
      setTimeout(() => document.getElementById(id).scrollIntoView({behavior: "smooth"}), TYPING_TIMEOUT + 20);
    }
    
  }
  
  render() {
    const { children, id, isFriend } = this.props;
    const { changeLoader } = this.state;

    return (
      <div className={`bubble-text-container-container ${isFriend ? '' : 'bubble-text-container-container--right'}`}>
        <div className="bubble-text-container">
          <div className={`bubble-container${isFriend ? '--friend' : ''}`} id={id}>
            {isFriend && !changeLoader
              ? <PulseLoader color="#b2b1b3" sizeUnit={"px"} size={13}/>
              : <span className="bubble-text">{children}</span>}
          </div>
        </div>
      </div>
    );
  }
}