import React, { Component } from 'react';
import './App.scss';
import SentenceBuilder from './sentence-builder/sentenceBuilder';
import Bubble from './components/bubble';
import { TYPING_TIMEOUT } from './sys-constants';
import FriendResponseBuilder from './sentence-builder/friendResponseBuilder';

const APP_INIT = 500;
const FRIEND_CHANCE = 0.3;

class App extends Component {
  constructor(props) {
    super(props);
    this.sentenceBuilder = new SentenceBuilder();
    this.friendResponseBuilder = new FriendResponseBuilder();
    this.state = {
      bubbleTexts: [],
      appState: 0,
    };
  }

  componentDidMount() {
    setTimeout(this.advanceApp, APP_INIT);
    setTimeout(this.advanceApp, APP_INIT + TYPING_TIMEOUT + 500 );
    setTimeout(this.advanceApp, APP_INIT + (TYPING_TIMEOUT + 500) * 2);
    setTimeout(this.advanceApp, APP_INIT + (TYPING_TIMEOUT + 500) * 3);
  }

  advanceApp = () => {
    const { appState, bubbleTexts } = this.state;
    switch (appState) {
      case 0:
        bubbleTexts.push({isFriend: true, text: "Yo wassup"});
        this.setState({ bubbleTexts, appState: appState + 1 });
      break;
      case 1:
        bubbleTexts.push({isFriend: true, text: "Where were you yesterday?"});
        this.setState({ bubbleTexts, appState: appState + 1 });
      break;
      case 2:
        bubbleTexts.push({ isFriend: true, text: "Tried calling you but couldn't reach you"});
        this.setState({ bubbleTexts, appState: appState + 1 });
      break;
      case 3:
        this.setState({ appState: appState + 1 });
      break;
      default: break;  
    }
  }

  changeSentence = () => {
    const { bubbleTexts } = this.state;
    bubbleTexts.push({text: this.sentenceBuilder.buildExpression()});
    if (Math.random() <= FRIEND_CHANCE) {
      bubbleTexts.push({isFriend: true, text: this.friendResponseBuilder.build()});
    }
    this.setState({ bubbleTexts });
  }

  render() {
    const { bubbleTexts, appState } = this.state;

    return (
      <div>
        <div className="cellphone">
          <div className="message-header">
            <div className="friend-icon"><i className="fas fa-user" /></div>
            <span className="friend-name">Friendo</span>
          </div>
          <div className="touch-container">
            <div className="screen-container">
              <div className="screen">
                <div className="screen-buffer"> </div>
                {bubbleTexts.map((el, index) =>
                  <Bubble id={index} key={`bubble_${index}`} isFriend={el.isFriend}>{el.text}</Bubble>)}
              </div>
            </div>
            <div className="button-container">
              <button disabled={appState <= 3} className="send-button" type="button" onClick={this.changeSentence}>
                <i className="fas fa-paper-plane"/>
              </button>
            </div>
          </div>
          <div className="phone-button">
            <div className="home-button"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
