import VerbDescriptorBuilder from './expression-builders/verbDescriptorBuilder';
import BodyVerbBuilder from './expression-builders/bodyVerbBuidler';
import { ADJECTIVES, IT_WAS_ACTIONS_WITH, I_WAS_ACTIONS, I_WAS_ACTIONS_TO, I_WAS_ACTIONS_WITH } from './constants/constants';
import { getRandom } from './utils/utils';
import ActionBuilder from './action-builders/actionBuilder';

const INITIAL_CONFIG = {
  expressionProbs: [0.5, 1],
  adjectiveProb: 0.5,
};

export default class SentenceBuilder {
  constructor(config = INITIAL_CONFIG) {
    this.expressionProbs = config.expressionProbs;
    this.adjectiveProb = config.adjectiveProb;
    this.actionBuilders = [
      new ActionBuilder('It was $ with my ', IT_WAS_ACTIONS_WITH),
      new ActionBuilder('I was $ my ', I_WAS_ACTIONS),
      new ActionBuilder('I was $ to my ', I_WAS_ACTIONS_TO),
      new ActionBuilder('I was $ with my ', I_WAS_ACTIONS_WITH),
    ];
    let acc = 0;
    this.actionProbs = this.actionBuilders.map((el) => {
        acc += el.getActionsLength();
        return acc;
      })
      .map(el => el / acc);
    this.expressionBuilders = [new VerbDescriptorBuilder(), new BodyVerbBuilder()];
  }

  buildAction() {

  }

  buildExpression() {
    const expressionSeed = Math.random();
    const actionSeed = Math.random();
    const adjective = Math.random() < this.adjectiveProb ? `${getRandom(ADJECTIVES)} ` : '';

    return this.actionBuilders[this.actionProbs.findIndex(el => el > actionSeed)].buildAction()
      + adjective
      + this.expressionBuilders[this.expressionProbs.findIndex(el => el > expressionSeed)].build();
  }
}