import { getRandom } from '../utils/utils';

export default class ActionBuilder {
  // Base should have $ in place of action
  constructor(base, actions) {
    this.base = base;
    this.actions = actions;
  }

  buildAction() {
    return this.base.replace('$', getRandom(this.actions));
  }

  getActionsLength() { return this.actions.length; }
}