export const VD_VERB = [
  'slam',
  'f*ck',
  'bump',
  'crunch',
  'flop',
  'fickin\'',
  'dickin\'',
  'grind',
  'squash',
  'smash',
  'flippity-flop',
  'stuffing',
  'munch',
  'frickity-frick-frack',
  'hump',
  'drill',
  'boink',
];

export const VD_DESCRIPTOR = [
  'piece',
  'slab',
  'chunk',
  'slice',
  'buddy',
  'partner',
  'pal',
  'fella',
  'boy',
  'girl',
  'friend',
  'acquaintance',
  'connection',
  'hunk',
  'date',
  'deal',
];

export const BV_BODY = [
  'd*ck',
  'butt',
  'ass',
  'body',
  'booty',
  'pussy',
  'bacon',
  'ball',
  'sausage',
  'boner',
  'bone',
  'pickle',
  'rump',
  'hump',
  'dingly-doo',
  'diddly-hole',
  'puss',
  'dong',
  'ding-dong',
  'junk',
];

export const BV_VERB = [
  'squisher',
  'squasher',
  'smasher',
  'grabber',
  'slammer',
  'f*cker',
  'grinder',
  'picker',
  'destroyer',
  'annihilator',
  'killer',
  'polisher',
  'shiner',
  'stuffer',
  'eater',
  'muncher',
];

export const ADJECTIVES = [
  'special',
  'precious',
  'faithful',
  'valiant',
  'beautiful',
  'funny little',
  'little',
  'squishy',
  'tasty',
  'big ol\'',
  'lil\' ol\'',
  'rooty-tooty',
  'fabulous',
  'super',
  'miracle',
  'cute little',
  'wonder',
  'wiggly',
  'jiggly',
  'jolly good',
  'good ol\'',
];

export const I_WAS_ACTIONS_WITH = [
  'doing an act of darkness',
  'balling',
  'playing hide-the-zucchini',
  'playing bam-bam in the ham',
  'bumping uglies',
  'having a *deal*',
  'having this *thing*',
  'beating guts',
  'smashing pissers',
  'bulging the back of the old onion bag',
  'meat spelunking',
  'crushing buns',
  'doing it',
  'doing the devil\'s dance',
  'doing the hibbety-dibbety',
  'having a gay old time',
  'doin\', y\'know,', 
  'entangling lower beards',
  'dancing the forbidden polka',
  'getting down',
  'getting busy',
  'hiding the bishop',
  'doing the horizontal disco',
  'peeling the tree bark',
  'rubbing fun bits',
  'leaving *no* space for jesus'
];

export const I_WAS_ACTIONS = [
  'plonking',
  'porking',
  'banging',
  'f*cking',
  'spreading',
  'filling up',
  'boinking',
  'organ riding',
  'humping',
  'fun-clobbering',
  'scoring',
  'screwing',
  'hammering',
  'drilling',
  'diddly-doodling',
  'hole-punching',
];

export const I_WAS_ACTIONS_TO = [
  'giving the business',
  'giving it',
  'dealing damage',
];

export const IT_WAS_ACTIONS_WITH = [
  'adult nap-time',
  'business time',
  'nude wrestling time',
];

export const FRIEND_REACTIONS = [
  '...oh',
  'God ok I got it',
  'Jesus christ ok',
  'Please stop',
  'Why are you like this',
  'Ok I got it',
  'Gotcha gotcha gotcha ok',
  '!!!',
  'Ok I gotcha',
  'Oh damn',
  'Dude ok I understand',
  'No no I got it',
  'God almighty',
  'God have mercy',
  'Jesus h christ',
  'Omg',
  'Omfg',
  'Stahp',
  'Oh no',
  'Nooooo',
  'Dude',
  'Bruh what',
  'Whyyyyy',
  'Whyyyyyyyyy',
  'Nooooooooooooooo',
  'Jeeeeez',
  'Oy gevalt',
  'Oy vey',
  'Yuck',
  'Ew stop',
  'Oh god why',
];
